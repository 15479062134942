<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>

        <div class="container">
            <!-- <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="订单编号:">
                    <el-input v-model="queryParams.ordersn" placeholder="请输入订单编号" clearable></el-input>
                </el-form-item>
                <el-form-item label="订单起止时间:" prop="time">
                    <el-date-picker ref="drawDate" style="margin-left: 5px;" v-model="queryParams.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable @change="changeQuery">
                    </el-date-picker>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form> -->
            <div>
                <el-radio-group v-model="tabPosition" style="margin-bottom:20px;" @change="changState">
                    <el-radio-button label="all">全部</el-radio-button>
                    <el-radio-button label="0">待成团</el-radio-button>
                    <el-radio-button label="1">成团中</el-radio-button>
                    <el-radio-button label="2">开团成功</el-radio-button>
                    <el-radio-button label="3">开团失败</el-radio-button>
                </el-radio-group>
            </div>
            <!-- 表格内容 -->
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable" style="margin-top:20px">
                <el-table-column prop="id" label="id" width="80" align="center"></el-table-column>
                <el-table-column prop="spell_id" label="拼团id" width="150" align="center"></el-table-column>
                <el-table-column prop="uid" label="用户id" width="150" align="center"></el-table-column>
                <el-table-column prop="goods_id" label="商品id" width="150" align="center"></el-table-column>
                <el-table-column prop="time" label="拼团有效期(小时)" width="150" align="center"></el-table-column>
                <el-table-column prop="number" label="成团人数" width="150" align="center"></el-table-column>
                <el-table-column prop="open_number" label="参与人数" width="150" align="center"></el-table-column>
                <el-table-column prop="open_time" label="开团时间" width="170" align="center"></el-table-column>
                <el-table-column label="拼团状态" align="center" width="150">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 0">待成团</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 1">成团中</el-tag>
                        <el-tag type="success" v-if="scope.row.state ===2">开团成功</el-tag>
                        <el-tag type="warning" v-if="scope.row.state ===3">开团失败</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" @click="info(scope.row.id)">拼团订单</el-button>
                        <!-- <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 新增 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
            <el-form ref="form" :model="form" label-width="120px" :rules="rules" label-position="left">
                <el-form-item label="备注" prop="remark">
                    <el-input v-model="form.remark" placeholder="请输入备注"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import Url from '@/components/Url';
import loadEvents from '@/utils/loading'
import { request } from '@/common/request';
import { deal } from '@/common/main';
let loads = new loadEvents();
export default {
    components: {
        Breadcrumb,
        Pagination,
        Url
    },
    data() {
        return {
            tabPosition: 'all',
            choseState: null,
            title: '新增用户',
            addVisible: false,
            pageInfo: {},
            menuList: ['订单', '拼团信息'],
            form: {
            },
            queryParams: {
                page: 1,
                pagesize: 10
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            rules: {
                uid: [{
                    required: true,
                    message: '备注不能为空',
                    trigger: 'blur'
                }],
            },
        }
    },
    created() {
        this.loadData()
    },
    watch: {
        $route(from) {
            let ArticleEdit = from.fullPath.indexOf('/order-orderDetails')
            if (ArticleEdit !== -1) {
                this.loadData()
            }
        }
    },
    methods: {

        //编辑
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑';
            this.addVisible = true;
            this.form.id = row.id
        },
        //商品列表
        loadData() {
            this.loading = true;
            let _this = this
            request.get('/order/spell/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map((val) => {
                        val.open_time = deal.timestamp(val.open_time, 'unix');
                        return val
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = '/order/spell/son/edit';
                        //处理时间
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        changeQuery() {
            this.queryParams.page = 1;
            if (this.queryParams.time) {
                this.queryParams.ctime = deal.timestamp(this.queryParams.time[0]);
                this.queryParams.etime = deal.timestamp(this.queryParams.time[1]);
                delete this.queryParams.time
            }
            if (this.choseState === 0) {
                this.queryParams.pay_state = 0
                this.queryParams.state = 0
            } else {
                this.queryParams.state = this.choseState
                delete this.queryParams.pay_state
            }
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            if (this.queryParams.time) {
                this.queryParams.ctime = deal.timestamp(this.queryParams.time[0]);
                this.queryParams.etime = deal.timestamp(this.queryParams.time[1]);
                delete this.queryParams.time
            }
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            if (this.queryParams.time) {
                this.queryParams.ctime = deal.timestamp(this.queryParams.time[0]);
                this.queryParams.etime = deal.timestamp(this.queryParams.time[1]);
                delete this.queryParams.time
            }
            this.loadData()
        },
        changState() {
            switch (this.tabPosition) {
                case 'all':
                    this.queryParams = {
                        page: 1,
                        pagesize: 10
                    }
                    break;
                case '0':
                    this.queryParams = {
                        page: 1,
                        pagesize: 10,
                        state: 0,
                    }
                    break;
                case '1':
                    this.queryParams = {
                        page: 1,
                        pagesize: 10,
                        state: 1,
                    }
                    break;
                case '2':
                    this.queryParams = {
                        page: 1,
                        pagesize: 10,
                        state: 2,
                    }
                    break;
                case '3':
                    this.queryParams = {
                        page: 1,
                        pagesize: 10,
                        state: 3,
                    }
                    break;
            }
            this.loadData()
        },
        info(id) {
            this.$router.replace({ path: '/order-CollageOrder', query: { 'id': id } })
        }
    }
};
</script>

<style  scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
</style>